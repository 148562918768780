<div class="form-select-menu">
  <button class="form-select-button" (click)="toggleOptions()" [disabled]="disabled">
    <strong>{{label}}</strong><span *ngIf="showSymbol" class="symbol {{selected?.value}}"></span>{{selected?.title}}
  </button>
  <ul class="form-select-dropdown" *ngIf="showOptions">
    <li *ngFor="let option of options; index as i"
        [class.selected]="option === selected"
        role="button"
        tabindex="0"
        (click)="select(option, i)"
        (keydown.enter)="select(option, i)"
        (keydown.space)="select(option, i); $event.preventDefault()">
      <span *ngIf="showSymbol" class="symbol {{option.value}}"></span>{{option.title}}
    </li>
  </ul>
</div>
